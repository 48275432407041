.prodDescContainer {
    position: relative;
    width:100%;
    height:100%;
    border-bottom: 1px solid rgb(222, 218, 218);
}
.prodNameHeader {
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 10px;
}
.prodDescRow{
    font-size: 0.8rem;
    color:grey;
    margin-bottom: 10px;
    display: block;
}
.prodDetailsOneRow{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 0.8rem;
    color:grey;
    display: flex;
}
.prodDetailslabel {
    margin-right: 5px;
}

.prodDescAddToCartContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.qtyCartRow{
    margin: 10px 0px;
    display: flex;
    justify-content: center;
}

.qtyIncrDcrBtn {
    border : solid 1px rgb(222, 222, 222);
    width: 25px;
    color: grey;
    font-weight:600 ;
    cursor: pointer;
}

.prodDescAddToCartBtn {
    background-color: orange;
    font-size: 0.8rem;
    padding: 4px 6px;
    color: white;
    cursor: pointer;
}

.prodDescNoteContainer {
    position:absolute;
  color: grey;
    bottom:0;
    right:0;
    font-size: 0.6rem;
}

.prodDescNotesBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}