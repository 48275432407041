.cartItemContainer {
    display: flex;
    width: 95%;
    padding: 10px;
    margin: 5px auto;
    border-bottom: 1px solid rgb(241, 238, 238);

}

.cartItemContainer:hover {
    background-color: #f7f6f6;
}

.noDecorationLinkCart{
    display: contents;
    cursor: pointer;
}
.cartItemImg {
   min-width: 75px;
   min-height: 75px;
    width: 7.5%;
    margin-right: 10px;
}

.nonImgCols {
    display: flex;
    width: 100%;
}

.nameDescCol {
    font-size: 0.8rem;
    width: 59.46%;
    /* 100-7.5 = 92.5 ; 55/92.5 = 59.46*/
}

.itemNameLable {
    color: green;
    font-weight: 600;
}
.qtyCol {
    width: 16.22%;
    display: flex;
    align-content: center;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.quantityLabel {
    display: none;
}

.cartQtyInput {
    border: solid 1px #dbd9d9;
    width: 30px;
    border-radius: 4px;
    text-align: center;
    font-size: .7rem;
    padding: 2px 0;
    margin: 0 5px;
    height: 18px
}

.cartDeleteItem {
    margin-left: 10px;
    opacity: 0.4;
}

.priceCol {
    font-size: 0.8rem;
    width: 11.5%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
    align-items: center;

}

.priceLabel {
    display: none;
}
.origPriceRow {
    text-decoration: line-through;
    color: grey
}

.totalCol {
    font-size: 0.8rem;
    width: 11.5%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.totalLabel {
    display: none;
}
.cartSavingsItems {
    color: grey;
    margin-left: 5px;
}

@media (max-width: 850px) {

    .cartItemImg {
        max-width: 100px;
        min-width: 85px;
        max-height: 100px;
    }

    .nonImgCols {
        flex-direction: column;
        align-items: center;
    }

    .nameDescCol {
        flex-direction: row;
        width: 100%;
        justify-content: center;
        align-content: center;
    }

    .qtyCol {
        flex-direction: row;
        width: 100%;
        margin-top: 10px;
justify-content: flex-start;
    }

    .quantityLabel {
        display: inline;
        font-size: 0.8rem;
        margin-right: 10px;
    }

    .priceCol {
        flex-direction: row;
        width: 100%;
        margin-top: 5px;
        justify-content: flex-start;
    }

    .priceLabel {
        display: inline;
        font-size: 0.8rem;
        margin-right: 10px;
    }
    .totalCol {
        flex-direction: row;
        width: 100%;
        margin-top: 5px;
        justify-content: flex-start;
    }

    .totalLabel {
        display: inline;
        font-size: 0.8rem;
        margin-right: 10px;
    }
}