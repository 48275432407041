.checkoutPageContainer {
    padding: 15px 10px;
    min-height: 80vh;
    max-width: 1300px;
    margin: 0 auto
}

.addressHeader {
    display: flex;
    background-color: orange;
    padding: 5px;
    align-items: center;
    font-size: 0.8rem;
    color: white;
    font-weight: 700;
    gap: 8px
}

.addressBodyMain {
    border: 1px solid rgb(227, 226, 226);
    max-width: 400px;
    margin: 2px auto;
}

.redAsterisk{
    color: red;
}

.addressInputContainer {
    padding: 10px 15px 5px 15px;
    font-size: 0.8rem;
    color: rgb(120, 120, 120);
}


.inputBox {
    margin-top: 8px;
    background: transparent;
    border: none;
    border-bottom: 1px solid rgb(227, 226, 226);
    width: 100%;
    ;
}

.cartErrorSubmission{
    color: red;
    text-align: center;
    margin-top: 10px;
    font-size: 0.9rem;
}
.checkoutCaptchaBox {
    margin-top: 10px;
    width: 100%; 
    display: flex;
    justify-content: center;
}
.termsCheckContainer {
    width: 200px;
    font-size: .75rem;
    margin: 10px auto;
}

.termsLinkcheckout{
    color:skyblue;
    cursor: pointer;
}
.placeOrderBtn {
    margin: 20px auto;
    width: 100px;
    background-color: orange;
    text-align: center;
    padding: 5px;
    font-size: 0.8rem;
    color: white;
    cursor: pointer;
}

.disclaimerText {
    color: rgb(120, 120, 120);
    font-size: 0.8rem;
    text-align: center;
}

