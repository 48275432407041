.proceedToCheckoutContainer {
    width: 95%;
    display: flex;
    justify-content: flex-end;
    margin : 5px auto;
    padding: 10px;
    font-size: 0.8rem;
    color:grey;
}
.cartSummaryBox {
    border: 1px solid rgb(221, 221, 221);
    padding: 5px
}

.summaryRow {
    text-align: center;
    margin: 5px 0;
    background-color: black;
    color: white;
    padding: 5px;
}
.qtyTotRow {
    margin: 5px
}
.grandTotRow {
    margin: 5px
}

.cartSummaryLinkNoDecoration {
    text-decoration: none;
}
.proceedToCheckoutBtn {
    background-color: orange;
    color: white;
    padding: 5px;
    text-align: center;
    cursor: pointer;
    
}