.cartPageContainer {
    padding: 15px 10px;
    min-height: 80vh;
    max-width: 1300px;
    margin: 0 auto
}

.cartEmptyMsg {
    margin: 15px auto;
    max-width: 500px;
    text-align: center;
    font-size: 1.5rem;
}

.cartNotPayNowNotice {
    text-align: right;
    font-size: 0.7rem;
    color: grey;
    margin-right: 20px;
    margin-bottom: 2px;
}
.cartHeading {
    margin: 15px 0;
    font-size: 0.9rem;
}

.cartTableHeading {
    width: 95%;
    margin: 0 auto;
    display: flex;
    padding: 10px;
    background-color: #f7f6f6;

}

.productHeading {
    width: calc(62.5% + 10px);
    /* the 10px is because of the margin of the images in the rows*/
    text-align: center;

}

.quantityHeading {
    width: 15%;
    /* the 10px is because of the margin of the images in the rows*/
    text-align: center;

}

.priceHeading {
    width: 10%;
    /* the 10px is because of the margin of the images in the rows*/
    text-align: center;

}

.totalHeading {
    width: 10%;
    /* the 10px is because of the margin of the images in the rows*/
    text-align: center;

}

.cartBox {
    padding: 2px;
    display: flex;
    flex-direction: column;
}

@media (max-width: 850px) { 
    .cartTableHeading {
        display: none;
    }
}