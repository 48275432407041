.productBox {
    min-height: 150px;
    max-width: 400px;
    background-color: white;
    margin: 5px;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    
}

.productImageLinkNoDecoration {
   text-decoration: none;
   width: 65%;
   height:50%;
   margin: 15px 5px;
   cursor: pointer;
}

.topProductImg {
 width: 100%;
 height: 100%; /* we are doing a fixed height so that all the images end at the same height*/
}

.prodNameLink {
text-decoration: none;
color:inherit;
 text-align: center;
 font-size: 75%;
 max-width: 80%;
 margin:0 0  5px 0;
 cursor: pointer;
}

.prodName { /* not usign it as we have Link as its parent and we are moving all properties there */
     text-align: center;
     font-size: 75%;
     max-width: 80%;
     margin:0 0  5px 0;
    }

.prodSize{
    font-size: .6rem;
    margin: 2px 0;
    max-width: 80%;
}
.prodPriceRow{
    margin: 5px 0px;
    display:flex;
}

.origPrice {
    text-decoration: line-through;
    color: #b1b1b1;
    margin-right: 10px;
    font-size: 80%;
}
.offerPrice {
    font-size: 80%;
}

.prodSaving {
    position:absolute;
    top:0;
    right:0;
    background-color: black;
    color: white;
    padding: 4px;
    font-size: .7rem;
}

.prodCartRow {

margin: 5px 0;       
display: flex; 
align-items: center;
justify-content: space-between;
align-content: center;
}



.qtyInput {
    border-style: solid;
    border-color: #dbd9d9;
    border-width: 1px;
    width: 30px;
    border-radius: 4px;
    text-align: center   ;
    font-size: 70%;
    padding: 2px 0;
    margin: 0 8px;
    height: 18px
}

.addToCartButton {
    font-size: 65%;
    background-color: orange;
    color:white;
    padding: 6px 6px;
    margin-left: 10px;
    cursor: pointer;
}